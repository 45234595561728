<template>
  <div class="containerFull">
    <div class="banner">
      <img src="../../assets/image/qydtBanner.png" alt />
    </div>
    <div class="container" style="box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);padding:20px">
      <div class="divList" v-for="(i,k) in list" :key="k" @click="toDetail(i)">
        <div style="width:280px;min-width:280px;height:180px;margin-right:20px">
          <img :src="i.filename" alt />
        </div>
        <div style="height:180px">
          <div class="title">{{i.title}}</div>
          <div
            class="con"
          >{{i.content}}</div>
          <div class="time">{{i.creationTime}}</div>
        </div>
      </div>
      <div class="block" style="text-align:center;margin:27px 27px 7px 27px; ">
        <el-pagination
          layout="prev, pager, next"
          :total="total"
          :current-page="pageNum"
          :page-size="pageSize"
          @current-change="pageNumChange"
          :hide-on-single-page="true"
          background
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { sysNewsList } from "../../api/enterpriseNews.js";
export default {
  data() {
    return {
      total: 0,
      pageNum: 1,
      pageSize: 10,
      list: []
    };
  },
  created() {
    this.sysNewsList();
  },
  methods: {
    pageNumChange(size) {
        console.log(size)
      this.pageNum = size;
      this.sysNewsList();
    },
    sysNewsList() {
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      };
      sysNewsList(params).then(res => {
        console.log(res);
        if(res.data.code==200){
            this.list=res.data.data.list
            this.total=res.data.data.total
            this.list.forEach(val=>{
              val.creationTime=val.creationTime.split(' ')[0]
              // val.content=val.content.replace(/<[^<>]+>/g,'').replace(/&nbsp;/ig,'')
              // console.log(val.content.length>110)
              // let a=""
              // val.content.length>110?a=val.content.slice(0,110)+'...':a=val.content
              // val.content=a
            })
        }
        console.log(this.list);
      });
    },
    toDetail(row){
      console.log(row)
      this.$router.push({
        path: "/newsDetail",
        query: {
          row: row.id,
        },
      })
    }
  }
};
</script>

<style  scoped>
.containerFull {
  width: 100%;
  margin-bottom: 40px;
}
.banner {
  width: 100%;
  height: 308px;
  margin-bottom: 40px;
}
.banner img {
  width: 100%;
  height: 100%;
}
.container {
  /* width: 70%;
  min-width: 1000px; */
  width: 1200px;
  margin: 0 auto;
}
.divList {
  height: 240px;
  box-sizing: border-box;
  padding: 20px 20px 40px 20px;
  display: flex;
  position: relative;
  margin-bottom: 20px;
}
.divList img {
  width: 100%;
  height: 100%;
}
.title {
  font-size: 20px;
  line-height: 60px;
  font-weight: 400;
  opacity: 1;
}
.con {
  font-size: 14px;
  color: #98a0ac;
  font-weight: 400;
  line-height: 30px;
  opacity: 1;
}
.time {
  font-size: 14px;
  color: #98a0ac;
  font-weight: 400;
  opacity: 1;
  position: absolute;
  bottom: 40px;
}
.active {
  color: #0070ff;
}
</style>